import React from "react";
import { ThemeProvider } from "styled-components";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Sidebar } from "../components/Sidebar";
import { MoreServiceContainer } from "../containers/MoreServiceContainer";
import FooterContainer from "../containers/FooterContainer";
import { Header } from "../containers/HeaderContainer";
import useApp, { withAppContext } from "../contexts/context";
import { useSiteMetadata } from "../hooks/useQuerydata";
import { useQueryMoreService } from "../hooks/useQueryMoreService";
import { useQueryService } from "../hooks/useQueryService";

const Service = () => {
  const { toggle, handleToggleLayout } = useApp();
  const {
    allPrismicFooter,
    allPrismicHeader,
  } = useSiteMetadata();
  const { allPrismicServices } = useQueryService()
  const { allPrismicMoreServices, allPrismicPartners } = useQueryMoreService()
  const dataHeader = allPrismicHeader?.nodes[0]?.data;
  const theme = {
    bgColor: "white",
  };
  return (
    <ThemeProvider theme={theme}>
      <Layout
        isHomepage
        toggle={toggle}
        toggleLayout={handleToggleLayout}
        header={
          <Header
            toggleLayout={handleToggleLayout}
            data={dataHeader}
          />
        }
        footer={
          <FooterContainer data={allPrismicFooter?.nodes[0]?.data} />
        }
        sidebar={
          <Sidebar
            toggle={toggle}
            handleToggle={handleToggleLayout}
            dataHeader={dataHeader}
            dataFooter={allPrismicFooter?.nodes[0]?.data}
          />
        }
        seo={<SEO data={allPrismicServices.nodes[0].data?.body7} />}
      >
        <MoreServiceContainer
          data={allPrismicMoreServices?.nodes[0]?.data}
          partners={allPrismicPartners?.nodes}
        />
      </Layout>
    </ThemeProvider>
  );
};

export default withAppContext(Service);
