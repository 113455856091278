import * as React from 'react'
import { Elements } from 'prismic-richtext'
import { Link as PrismicLink } from 'prismic-reactjs'
import { Text } from '../../../components/Text/Text'
import { Link } from '../../../components/Link/Link'
import { color, typography } from '../../../shared/style'


//embed link converter

const getId = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);

  return (match && match[2].length === 11)
    ? match[2]
    : null;
}


// -- HTML Serializer
const htmlSerializer = function (type, element, content, children, key) {

  switch (type) {
    case Elements.heading1: // Heading 1
      return <Text key={key}>{children}</Text>

    case Elements.heading2: // Heading 2
      return <Text tag="h2"
        capital="uppercase"
        color={color.dark.dark100}
        weight={600}
        font={typography.type.code} key={key}>{children}</Text>

    case Elements.heading3: // Heading 3
      return <Text tag="h3"
        capital="uppercase"
        // color={color.dark.dark100}
        // weight={600}
        font={typography.type.code}
        key={key}
      >{children}
      </Text>

    case Elements.heading4: // Heading 4
      return <Text tag="h4"
        capital="uppercase"
        color={color.dark.dark100}
        weight={600}
        font={typography.type.code} key={key}>{children}</Text>

    case Elements.heading5: // Heading 5
      return <Text tag="h5"
        capital="uppercase"
        color={color.dark.dark100}
        weight={600}
        font={typography.type.code} key={key}>{children}</Text>

    case Elements.heading6: // Heading 6
      return <Text tag="h6"
        capital="uppercase"
        color={color.dark.dark100}
        weight={600}
        font={typography.type.code} key={key}>{children}</Text>

    case Elements.paragraph: // Paragraph
      return (
          <Text
            font={typography.type.primary}
            key={key}>
            {children}<br /><br />
          </Text>
      )

    case Elements.preformatted: // Preformatted
      return (
        <div key={key} dangerouslySetInnerHTML={{ __html: children }} />
      )

    case Elements.strong: // Strong
      return <strong key={key}>{children}</strong>

    case Elements.em: // Emphasis
      return <em key={key}>{children}</em>

    case Elements.listItem: // Unordered List Item
    case Elements.oListItem: // Ordered List Item
      return <li key={key}>{children}</li>

    case Elements.list: // Unordered List
      return <ul key={key}>{children}</ul>

    case Elements.oList: // Ordered List
      return <ol key={key}>{children}</ol>

    case Elements.image:
      const linkUrl = element.linkTo
        ? element.linkTo.url || (element.linkTo)
        : null

      return (
        <p
          key={key}
          className={[element.label, 'block-img'].filter(Boolean).join(' ')}
        >
          {linkUrl ? (
            <a
              href={linkUrl}
              target={element.linkTo?.target}
              rel={element.linkTo?.target ? 'noopener' : undefined}
            >
              <img src={element.url} alt={element.alt || ''} />
            </a>
          ) : (
            <img src={element.url} alt={element.alt || ''} />
          )}
        </p>
      )

    case Elements.embed: // Embed
      const videoId = getId(element.oembed.embed_url);
      return (
        <div>
          <iframe
            title={videoId}
            width="100%"
            height="350px"
            src={`//www.youtube.com/embed/${videoId}`}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen="allowfullscreen">
          </iframe>
        </div>
      )

    case Elements.hyperlink: // Hyperlinks
      const url = PrismicLink.url(element.data)
      return (
        <Link
          fontFamily={typography.type.primary}
          size={typography.size.s3}
          key={key}
          to={url}
        >
          {content}
        </Link>
      )

    case Elements.label: // Label
      return (
        <>
          <Text
            color={color.dark.dark100}
            weight={600}
            font={typography.type.primary}
            key={key} className={element.data.label}
          >
            {children}
          </Text><br /><br />
        </>
      )

    case Elements.span: // Span
      if (content) {
        return content.split('\\n').reduce((acc, p) => {
          if (acc.length === 0) {
            return [p]
          } else {
            const brIndex = (acc.length + 1) / 2 - 1
            return acc.concat([<br key={brIndex} />, p])
          }
        }, [])
      } else {
        return null
      }

    default:
      // Always include a default that returns null
      return null
  }
}

export default htmlSerializer