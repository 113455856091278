import React, { useEffect, useState } from "react";
import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
import snake from "../../images/snake.gif";
import { Button } from "../../components/Button/Button";
import brand1 from "../../images/Service-staup-barnd-1.png";
import brand2 from "../../images/Service-staup-barnd-2.png";
import brand3 from "../../images/Service-staup-barnd-3.png";
import searchIcon from "../../images/Service-more-search.png";
import ArrowDownIcon from "../../images/Service-more-arrow-down.png";
import { useRef } from "react";
import CourseItem from "../ServiceContainer/CoreseItem";
import htmlSerializer from "../SpaceConntainer/RichtextHelper/CustomText";
import { RichText } from "prismic-reactjs";
import useWindowSize from "../AcademyDetailContainer/useWindowSize";

const ExclusiveOffer = ({ data, title, description, partners, ...props }) => {
  const [listShow, setListShow] = useState([]);
  const [showMore, setShowMore] = useState(true);
  const [index, setIndex] = useState(0);
  const dropdown = useRef();
  const dropdownContent = useRef();
  const [width] = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [keySearch, setKeySearch] = useState("");
  const [keyFilter, setKeyFilter] = useState("");

  useEffect(() => {
    if (width < 650) {
      setIndex(4);
    } else {
      setIndex(9);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      if (index > 0 || keySearch || keyFilter) {
        let newList = [...data];
        if (keySearch) {
          newList = data.filter((item) =>
            item?.service_and_offer?.document?.data?.banner_title?.text
              .toLowerCase()
              .includes(keySearch.toLowerCase())
          );
        }
        if (keyFilter) {
          newList = data.filter((item) =>
            item?.service_and_offer?.document?.data?.owner?.document?.data?.name?.text
              .toLowerCase()
              .includes(keyFilter.toLowerCase())
          );
        }

        setListShow(newList.splice(0, index));
      }
    }, 1000);

    if (index >= data.length) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  }, [index, keySearch, keyFilter, data]);

  const handleLoardMore = () => {
    if (width < 650) {
      setIndex((pre) => pre + 4);
    } else {
      setIndex((pre) => pre + 9);
    }
    setLoading(true);
  };

  const handleSearch = (e) => {
    setKeySearch(e.target.value);
    setLoading(true);
    if (width < 650) {
      setIndex(4);
    } else {
      setIndex(9);
    }
  };

  const handleFilter = (value) => {
    let temp = value;
    dropdown.current.innerHTML = value;
    if (value === "All Partners") {
      temp = "";
    }
    setKeyFilter(temp);
    setLoading(true);
    if (width < 650) {
      setIndex(4);
    } else {
      setIndex(9);
    }
  };
  if (!data) return null;
  return (
    <WrapperSection>
      <WrapHeader>
        <TitleHeader>
          <Text
            tag="h3"
            weight={typography.weight.regular1}
            color={color.primary.blue}
            font={typography.type.code}
            size={typography.size.code}
            capital="uppercase"
            lineHeight={90}
          >
            <RichText render={title.raw} htmlSerializer={htmlSerializer} />
          </Text>
        </TitleHeader>

        <DescriptionHeader>
          <Text
            tag="p"
            weight={typography.weight.regular}
            color={color.netraul.black100}
            font={typography.type.primary}
            size={typography.size.s3}
            lineHeight={26}
          >
            <RichText
              render={description.raw}
              htmlSerializer={htmlSerializer}
            />
          </Text>
        </DescriptionHeader>
      </WrapHeader>
      <WrapBody>
        <BodyAction>
          <ActionSearch>
            <SearchInput
              placeholder="Find offer..."
              onChange={(e) => handleSearch(e)}
            />
            <IconSearchContainer>
              <Image src={searchIcon} alt="iconSearch"></Image>
            </IconSearchContainer>
          </ActionSearch>
          <ActionFilter>
            <FilterLabel>Filter by: </FilterLabel>
            <DropdownContainer>
              <Dropdown ref={dropdown}>
                <span>All Partners</span>
              </Dropdown>
              <IconDropDown>
                <Image src={ArrowDownIcon} alt="icon"></Image>
              </IconDropDown>
              <DropdownContent
                ref={dropdownContent}
                className="dropdown-content"
              >
                <DropdownItem onClick={() => handleFilter("All Partners")}>
                  All Partners
                </DropdownItem>
                {partners &&
                  partners.map((item, i) => (
                    <DropdownItem
                      key={i}
                      onClick={() => handleFilter(item?.data?.name?.text)}
                    >
                      {item?.data?.name?.text}
                    </DropdownItem>
                  ))}
                {/* <DropdownItem
                  onClick={() => handleFilter("Real skill Education")}
                >
                  Real skill Education
                </DropdownItem> */}
              </DropdownContent>
            </DropdownContainer>
          </ActionFilter>
        </BodyAction>
        <BodyList>
          {listShow.length > 0 ? (
            listShow?.map((item, index) => (
              <CourseItem
                item={item?.service_and_offer?.document}
                key={index}
              />
            ))
          ) : (
            <TextNoResult align="center" font={typography.type.primary}>
              No result
            </TextNoResult>
          )}
          {loading && (
            <ContainerLoading>
              <Loading src={snake} alt="loading"></Loading>
            </ContainerLoading>
          )}

          {data?.length > 6 ? (
            <ButtonContainer>
              <ButtonExtended
                onClick={handleLoardMore}
                type="primary"
                disable={!showMore}
              >
                Load More
              </ButtonExtended>
            </ButtonContainer>
          ) : null}
        </BodyList>
      </WrapBody>
    </WrapperSection>
  );
};
const TextNoResult = styled(Text)`
  display: block;
  width: 100%;
`;
const Loading = styled.img`
  width: 30px;
`;
const ContainerLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const WrapperSection = styled.div`
  max-width: 1500px;
`;

const WrapHeader = styled.div`
  padding: 100px 131px 83px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  span {
    display: block;
  }
  @media (max-width: 1200px) {
    padding: 60px 20px 68px;
  }
  @media (max-width: 920px) {
    padding: 60px 20px 68px;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    padding: 60px 20px 68px;
  }
`;
const TitleHeader = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  box-sizing: border-box;
  @media (min-width: 1400px) {
    padding-right: 100px;
  }
  @media (max-width: 1200px) {
    max-width: 60%;
    flex: 0 0 60%;
  }
  @media (max-width: 992px) {
    max-width: 50%;
    flex: 0 0 50%;
    span {
      font-size: 56px !important;
      line-height: 56px;
      letter-spacing: -0.015em;
      font-weight: 500;
    }
  }
  @media (max-width: 650px) {
    max-width: 100%;
    flex: 0 0 100%;
    span {
      font-size: 62px !important;
      line-height: 62px;
      letter-spacing: -0.015em;
      font-weight: 500;
    }
  }
`;
const DescriptionHeader = styled.div`
  flex: 0 0 50%;
  margin-top: 5px;
  max-width: 50%;
  box-sizing: border-box;
  padding-left: 70px;
  div {
    margin-bottom: 32px;
  }
  @media (max-width: 1200px) {
    flex: 0 0 40%;
    max-width: 40%;
    margin-top: 0;
  }
  @media (max-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 650px) {
    padding-left: 0;
    margin-top: 30px;
    width: 100%;
    min-width: 100%;
  }
`;

const WrapBody = styled.div`
  padding: 0 131px 110px;
  background-color: ${color.primary.blue10};
  @media (max-width: 1200px) {
    padding: 0 20px 70px;
  }
`;

const BodyAction = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 50px 0;

  @media (max-width: 650px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

const ActionSearch = styled.div`
  border: 1px solid rgba(35, 35, 39, 0.2);
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  overflow: hidden;
  background-color: ${color.primary.white};
  @media (max-width: 700px) {
    margin-right: 35px;
  }
  @media (max-width: 655px) {
    margin-right: 0;
    margin-bottom: 14px;
  }
`;
const SearchInput = styled.input`
  border: none;
  width: 512px;
  height: 65px;
  background: inherit;
  font-size: 16px;
  font-weight: 400;
  color: rgba(35, 35, 39, 0.5);

  &:focus {
    outline: none;
  }

  @media (max-width: 992px) {
    width: 275px;
  }
  @media (max-width: 780px) {
    width: 250px;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;
const IconSearchContainer = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionFilter = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 650px) {
    justify-content: flex-end;
  }
`;
const FilterLabel = styled.label`
  margin-right: 16px;
  line-height: 26px;
  color: ${color.netraul.black100};
`;
const DropdownContainer = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    background: transparent;
    top: 90%;
    left: 0;
    height: 20px;
    width: 100%;
  }

  &:hover {
    .dropdown-content {
      opacity: 1;
      visibility: visible;
    }
  }
`;
const Dropdown = styled.div`
  width: 240px;
  height: 65px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: ${color.netraul.black100};
  padding-left: 22px;
  background: #ffffff;
  border: 1px solid rgba(35, 35, 39, 0.2);
  box-sizing: border-box;
  border-radius: 50px;
  cursor: pointer;
  @media (max-width: 780px) {
    width: 190px;
  }
`;
const DropdownContent = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  position: absolute;
  background-color: ${color.primary.white};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  width: 240px;
  top: 110%;
  left: 0;
  overflow: hidden;
`;
const DropdownItem = styled.div`
  cursor: pointer;
  padding: 8px 0px 8px 22px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const IconDropDown = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BodyList = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
  margin: 0 -17px;

  @media (max-width: 650px) {
    margin-top: 50px;
    margin: 0 -20px;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 16px;
`;

const ButtonExtended = styled(Button)`
  border-radius: 40px;
  height: 65px;
  white-space: nowrap;
  text-transform: capitalize;
  padding: 20px 45px;
`;

ExclusiveOffer.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      brand: PropTypes.string,
      title: PropTypes.string,
      color: PropTypes.string,
      partners: PropTypes.string,
      description: PropTypes.string,
      btn: PropTypes.string,
      dateTemp: PropTypes.string,
    })
  ),
};

ExclusiveOffer.defaultProps = {
  data: {
    primary: {
      title: {
        raw: [
          {
            type: "preformatted",
            text: "Offer & Services from our Partners",
            spans: [],
          },
        ],
      },
      description: {
        raw: [
          {
            type: "preformatted",
            text:
              "Draper Startup House supports an ecosystem of partners that includes educators, investors, SaaS providers, consultants and more.",
            spans: [],
          },
          {
            type: "preformatted",
            text:
              "Explore offers and services - curated especially for entrepreneurs and startups.",
            spans: [],
          },
        ],
      },
    },
    list: [
      {
        brand: brand1,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        partners: "Advance QueenSland",
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
      },
      {
        brand: brand2,
        title: "Leadership: How to define your vision.",
        color: color.primary.green40,
        partners: "Real skill Education",
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
      },
      {
        brand: brand3,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        partners: "Advance QueenSland",
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
      },
      {
        brand: brand1,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
      {
        brand: brand2,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Advance QueenSland",
      },
      {
        brand: brand3,
        title: "Leadership: How to define your vision.",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
      {
        brand: brand1,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Advance QueenSland",
      },
      {
        brand: brand2,
        title: "Leadership: How to define your vision.",
        color: color.primary.green40,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
      {
        brand: brand3,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Advance QueenSland",
      },
      {
        brand: brand1,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
      {
        brand: brand2,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Advance QueenSland",
      },
      {
        brand: brand3,
        title: "Leadership: How to define your vision.",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
      {
        brand: brand1,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Advance QueenSland",
      },
      {
        brand: brand2,
        title: "Leadership: How to define your vision.",
        color: color.primary.green40,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
      {
        brand: brand3,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Advance QueenSland",
      },
      {
        brand: brand1,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
      {
        brand: brand2,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Advance QueenSland",
      },
      {
        brand: brand3,
        title: "Leadership: How to define your vision.",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
      {
        brand: brand1,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
      {
        brand: brand2,
        title: "Digital Product Management: Modern Fundamentals",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Advance QueenSland",
      },
      {
        brand: brand3,
        title: "Leadership: How to define your vision.",
        color: color.primary.blue,
        description:
          "lorem ipsum dolor sit amet e.Curabitur mollis tempus orci, eu egestas ante pretium vem ipsum dolom ipsu.",
        btn: "Learn more",
        date: "August 23 - September 6, 2021",
        partners: "Real skill Education",
      },
    ],
  },
};

export default ExclusiveOffer;
