import { useStaticQuery, graphql } from "gatsby"

export const useQueryMoreService = () => {
  const { allPrismicMoreServices, allPrismicPartners } = useStaticQuery(
    graphql`
      query QueryMoreService {
        allPrismicMoreServices {
          nodes {
            data {
              title {
                html
                text
                raw
              }
              description {
                html
                text
                raw
              }
              body {
                ... on PrismicMoreServicesBodyMarketing {
                  primary {
                    title1 {
                      html
                      text
                      raw
                    }
                    button_label {
                      html
                      text
                      raw
                    }
                  }
                }
              }
              more_services {
                service_and_offer {
                  document {
                    ... on PrismicCourseDetail {
                      uid
                      data {
                        banner_title {
                          text
                          raw
                          html
                        }
                        banner_description {
                          text
                          raw
                          html
                        }
                        body {
                          ... on PrismicCourseDetailBodyAboutCourse {
                            primary {
                              start_date(formatString: "MMMM DD")
                              end_date(formatString: "MMMM DD, YYYY")
                            }
                          }
                        }
                        owner {
                          document {
                            ... on PrismicPartners {
                              data {
                                name {
                                  html
                                  text
                                  raw
                                }
                                logo {
                                  url
                                  alt
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        allPrismicPartners {
          nodes {
            data {
              name {
                text
              }
            }
          }
        }
      }
      `
  )

  return {
    allPrismicMoreServices,
    allPrismicPartners
  }
}