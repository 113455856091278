import React from "react";
import styled from "styled-components";
import ExclusiveOffer from "./ExclusiveOffer";
import Summary from "./Sumary";

export const MoreServiceContainer = ({ data, partners }) => {
  return (
    <WrapperLayout>
      <ExclusiveOffer
        title={data?.title}
        description={data?.description}
        data={data?.more_services}
        partners={partners}
      />
      <Summary
        data={data?.body[0] === undefined ? null : data?.body[0]?.primary}
      />
    </WrapperLayout>
  );
};

const WrapperLayout = styled.div`
  margin-bottom: 100px;
  max-width: 1500px;
  margin: 0 auto;
  margin-top: 90px;
  position: relative;
`;
