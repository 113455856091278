import React from "react";
import styled from "styled-components";
import { Button } from "../../components/Button/Button";
import { Text } from "../../components/Text/Text";
import { color, typography } from "../../shared/style";

const Summary = ({ data }) => {
  const redirectPage = (url) => {
    window.location.href = "#";
  };
  if (!data) return null;
  return (
    <WrapperSection>
      <Header
        tag="h4"
        weight={typography.weight.regular}
        color={color.primary.white}
        font={typography.type.code}
        size={typography.size.l3}
        capital="uppercase"
        lineHeight={56}
        align="center"
      >
        {data?.title1?.text}
      </Header>
      <HeaderMobile
        tag="h4"
        weight={typography.weight.regular}
        color={color.primary.white}
        font={typography.type.code}
        size={typography.size.l3}
        capital="uppercase"
        lineHeight={56}
        align="center"
      >
        You are a business wanting to connect with our pool of startups to
        quickly scale your business?
      </HeaderMobile>
      <Btn onClick={() => redirectPage()} type="tertiary">
        {data?.button_label?.text}
      </Btn>
      <BtnMobile onClick={() => redirectPage()} type="tertiary">
      Become our service partner
      </BtnMobile>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  max-width: 1500px;
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${color.primary.blue};
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
  }
`;

const Header = styled(Text)`
  width: 796px;
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
    width: 600px;
  }
  @media (max-width: 650px) {
    display: none;
  }
`;

const HeaderMobile = styled(Text)`
  display: none;
  letter-spacing: -0.015em;
  @media (max-width: 650px) {
    width: 335px;
    font-size: 36px !important;
    line-height: 36px;
    font-weight: 500;
    display: block;
  }
`;

const Btn = styled(Button)`
  border-radius: 40px;
  margin-top: 40px;
  @media (max-width: 650px) {
    display: none;
  }
`;
const BtnMobile = styled(Button)`
  border-radius: 40px;
  margin-top: 40px;
  display: none;
  @media (max-width: 650px) {
    display: block;
  }
`;

export default Summary;
